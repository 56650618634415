import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { IllustrationList } from '@docs/helpers';
import { Playground } from 'docz';
import * as Illustrations from './';
import { Flex } from '../ui/layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "illustrations"
    }}>{`Illustrations`}</h1>
    <blockquote>
      <p parentName="blockquote">{`A picture is worth a thousand words. An illustration makes complex ideas more accessible. They represent our brand - personality, voice, and platform - in an efficient and clear way. Just like the written word, illustrations exist to tell stories and thoughtfully convey ideas - they should not be used as decoration or without consideration.`}</p>
    </blockquote>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import * as Illustrations from '../../../illustrations'
`}</code></pre>
    <p>{`Render an illustration as a react component by appending it's name after the `}<inlineCode parentName="p">{`Illustrations`}</inlineCode>{` import. Illustrations support the `}<inlineCode parentName="p">{`space`}</inlineCode>{`, `}<inlineCode parentName="p">{`width`}</inlineCode>{` and `}<inlineCode parentName="p">{`height`}</inlineCode>{` shorthands from styled-system.`}</p>
    <Playground __position={0} __code={'<Flex justifyContent=\"center\">\n  <Illustrations.Build width=\"128px\" height=\"128px\" />\n  <Illustrations.Empty pl={7} mt={3} />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      IllustrationList,
      Playground,
      Illustrations,
      Flex,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex justifyContent='center' mdxType="Flex">
    <Illustrations.Build width='128px' height='128px' />
    <Illustrations.Empty pl={7} mt={3} />
  </Flex>
    </Playground>
    <h2 {...{
      "id": "all-illustrations"
    }}>{`All Illustrations`}</h2>
    <IllustrationList Illustrations={Illustrations} mdxType="IllustrationList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      